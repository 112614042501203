/* You can add global styles to this file, and also import other style files */
/* All sortable column of table are blue */

*{
    box-sizing: border-box;
  }
  
  
  html,body{
    background: #252525;
    color: white;
    height: 100vh;
    font-family: Open Sans !important;
  }
  
  .p-r{
    position: relative !important;
  }
  th[sortable]{
      color: #192EB0;
  }
  .modal {
    z-index: 1050; /* Ensure modal has a higher z-index than other elements */
  }
  
  :root {
    --blue: rgba(0, 23, 145, 1);
    --skyblue: rgba(45, 114, 146, 1);
  }
  .f-12{
    font-size: 12px !important;
  }
  .mt-10{
    margin-top: 10px !important;
  }
  .icons-img{
    width: 15px;
    height: 15px;
  }
  
  tr{
    font-size: 11px;
  }
  tr th{
    font-size: 13px;
  }
  table th{
    font-size: 13px !important;
  }
  table td{
    font-size: 11px !important;
  }
  table tr th:first-child{
    width: 60px !important;
  }
  
  /* table tr td:first-child{
    width: 30px !important;
  } */
input:disabled {
  cursor: not-allowed;
}

select:disabled {
  cursor: not-allowed;
}

/* css issues */
.flex_row {
  display: flex !important;
  flex-direction: row !important;
}

.flex_column {
  display: flex !important;
  flex-direction: column !important;
  margin-top: 2px;
}

.flex_grow_2 {
  flex-grow: 2;
}

.flex_wrap {
  flex-wrap: wrap !important;
}

.justify_content_space_evenly {
  justify-content: space-evenly !important;
}

.justify_content_space_around {
  justify-content: space-around !important;
}

.justify_content_center {
  justify-content: center !important;
}

.align-start-end {
  display: flex;
  justify-content: space-between;
}

.b-b {
  border-bottom: 1px solid #3d3c3f;
}

.p-b-16 {
  padding-bottom: 16px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-16 {
  margin-top: 16px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

/* .container{
    width: calc(80vw) ;
    margin: 0 auto !important;
  } */
.container_expand {
  width: 100% !important;
  height: 100% !important;
}

@media only screen and (max-width: 700px) {
  .container_expand {
    width: fit-content !important;
  }
}


.vertical span {
  /* display: flex; */
  /* flex-direction: column; */
  width: 80%;
}

/* .vertical span span{
    width: 100%;
    display: block;
  } */


input,
select,
option {
  background-color: #585858 !important;
  color: #fff !important;
}



html *::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

html *::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

html *::-webkit-scrollbar-thumb {
  background-color: #313131 !important;
  outline: 1px solid #191919 !important;
}

.page-link {
  background-color: #252525 !important;
  color: #fff !important;
}

.page-item.active .page-link {
  background-color: rgb(26, 26, 169) !important;
  color: #fff !important;
}

.grey-head {
  color: #B6B6B6;
}

.modal-backdrop {
  z-index: 960 !important;
}

.modal {
  z-index: 961 !important;
}

.f-f-open-sans {
  font-family: Open Sans !important;
}

.f-14 {
  font-size: 14px !important;
}
.f-8 {
  font-size: 8px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-24 {
  font-size: 24px !important;
}

.c-p {
  cursor: pointer !important;
}

.vertical-line {
  background: #5B5B5B;
  width: 1px;
  height: 12px;
  margin-right: 5px;
}

i {
  cursor: pointer;
}

.gap-5 {
  gap: 5px !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-12 {
  gap: 12px !important;
}
.gap-14 {
  gap: 14px !important;
}

.gap-15 {
  gap: 15px !important;
}

.gap-16 {
  gap: 16px !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-25 {
  gap: 25px !important;
}

.gap-30 {
  gap: 30px !important;
}

.gap-35 {
  gap: 35px !important;
}

.gap-40 {
  gap: 40px !important;
}

.gap-45 {
  gap: 45px !important;
}

.gap-50 {
  gap: 50px !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}

.grey-line {
  width: 1px;
  height: 15px;
  background: #5b5b5b;
}

.modal-open .modal {
  background: rgb(0, 0, 0, 80%) !important;
}

.swal2-input {
  max-width: 19em !important;
  background-color: white !important;
  color: #313131 !important;
}

.pac-container {
  z-index: 2000;
}

.f-f-i {
  font-family: 'FontAwesome' !important;
}

.align-left {
  float: left;
}

.w-100 {
  width: 100% !important;
}

.align-right {
  float: right;
}

.popup-input {
  border-radius: 4px !important;
  border: 1px solid #737373 !important;
  width: 100%;
  height: 50px;
  background: transparent !important;
  font-family: Open Sans !important;
  padding: 15px !important;
}

.cursor-blocked {
  cursor: not-allowed !important;
}

.popup-btn {
  width: 100%;
  height: 50px;
  border-radius: 4px !important;
  border: 1px solid #D9D9D9 !important;
  background: transparent !important;
  color: #D9D9D9 !important;
  font-size: 12px;
  font-family: Open Sans !important;
  cursor: pointer;
}

.cancel-btn {
  width: 100%;
  min-width: 250px;
  height: 45px;
  border-radius: 4px !important;
  border: 1px solid #FF3B30 !important;
  background: transparent !important;
  color: #FF3B30 !important;
  font-size: 12px !important;
  font-family: Open Sans !important;
  cursor: pointer;
}

.submit-btn {
  width: 100%;
  height: 45px;
  min-width: 250px;
  border-radius: 4px !important;
  border: none !important;
  background: #2C66E3 !important;
  color: #fff !important;
  font-size: 12px !important;
  font-family: Open Sans !important;
  cursor: pointer;
}

*:focus {
  outline: none !important;
}

input:focus {
  outline: none !important;
}

:host::ng-deep .ui-dropdown-panel .ui-dropdown-items {
  background-color: red !important;
}

:host::ng-deep .ui-dropdown-panel .ui-dropdown-items-wrapper {
  background-color: red !important;
}

:host::ng-deep body .ui-dropdown-panel .ui-dropdown-items {
  background-color: red !important;
}

:host::ng-deep body .ui-dropdown-panel .ui-dropdown-items-wrapper {
  background-color: red !important;
}

/* :host::ng-deep body .ui-dropdown-panel{
  background-color: red !important;
} */
:host::ng-deep .service-drop .ui-dropdown-panel {
  background-color: red !important;
}

.slots-visual-cont .ui-dropdown,
.slots-visual-cont .ui-inputtext,
.slots-visual-cont .ui-dropdown-trigger {
  background: none !important;

}

.slots-visual-cont .ui-dropdown {
  border: none !important;
}

.time-slot-drop .ui-state-default {
  min-width: 0px !important;
}

.map-container-slots .gm-style-iw-c {
  background: #202020 !important;
  background: #202020 !important;
  border: 0.5px solid #8D8D8D;
  padding: 15px;
  padding-right: 15px !important;
  padding-bottom: 15px !important;
}

.map-container-slots .gm-style-iw-d {
  overflow: hidden !important;
}

.t-a-c{
  text-align: center;
}

/* .dropdown .ui-dropdown-list  .ui-dropdown-item{
  text-align: right !important;
} */



/* .ui-dropdown-list .ui-state-highlight{
  background-color: #283544 !important;
} */

.multiSelect .ui-multiselect {
  /* Your styles for the ui-multiselect class */
  background: none;
  border: none;
  max-width: 65px;
}

.input-box{
  background: transparent !important;
  border-radius: 4px;
  border: 0.5px solid #737373;
  color: #898989 !important;
  font-size: 12px;
  padding: 8px;
}

.multiSelect .ui-multiselect .ui-multiselect-label {
  font-size: 9px;
  height: 22px !important;
}

.multiSelect .ui-multiselect .ui-multiselect-trigger {
  background: none;
  top: -2px !important;
  width: auto;
}

.multiSelect .ui-multiselect .ui-multiselect-trigger-icon {
  font-size: 0.8em !important;
}

.submit-form-btn {
  border-radius: 4px;
  background: #2C66E3;
  border: none;
  font-size: 12px;
  padding: 0px 12px;
  height: 36px;
  justify-content: center;
  min-width: fit-content;
  color: rgba(255, 255, 255, 1);
  width: 126px;
  cursor: pointer;
}

.submit-disabled-btn {
  border-radius: 4px;
  border: 0.5px solid #767676;
  background: #3B3B3B;
  color: #0C0C0C;
  cursor: not-allowed;
}

.cancel-black-btn {
  border-radius: 4px;
  background: none;
  border: 0.5px solid #FFFDFD;
  font-size: 12px;
  padding: 0px 12px;
  height: 36px;
  justify-content: center;
  min-width: fit-content;
  color: rgba(255, 255, 255, 1);
  width: 126px;
  cursor: pointer;
}
.submit-form-btn-dynamic{
  background: #2C66E3 !important;
  border: none  !important;
}
  .list-drivers  .modal-body {
    height: 700px !important;
  }
 .reason-select .ui-multiselect-item .ui-chkbox {
    display: none !important;
}
.ui-multiselect-panel,.ui-widget-header{
  background: #202020 !important;
}
